.header {
    margin-top: 1em;
    display: flex;
    flex-direction: column;
    
    @include breakpoint(large) {
        flex-direction: row;
        align-items: flex-start;

        .header__title {
            margin-left: 2em;
        }
    }
}

.header__contact {
    * + * {
        margin-left: 4px;
    }

    a {
        display: inline-block;
        text-decoration: none;
        height: 24px;
    }

    svg {
        height: 100%;
        transition: 300ms;

        &:hover, 
        &:focus {
            fill: $COLOR_NOTEBOOK_BLUE;
            transform: scale3d(1.15,1.15,1);
        }
    }
}

.header__imageContainer {
    position: relative;
    margin-bottom: 1em;
}

.header__image {
    display: block;
    object-fit: cover;
    height: 125px;
    flex-shrink: 0;
}


.js-profilePicSVG {
    display: block;
    position: absolute;
    left: -5px;
    top: -5px;
    width: calc(100% + 10px);
    height: calc(100% + 10px);
}