$COLOR_BLACK: #333;
$COLOR_NOTEBOOK_BLUE: #455468;


@mixin breakpoint($bp) {
    @if $bp == large {
        @media (min-width: 1200px) {
            @content;
        }
    }
    @else if $bp == medium {
        @media(min-width: 550px) {
            @content;
        }
    }
}

@for $i from 1 to 10 {
    .vr--#{$i} {
        margin-bottom: #{$i * 8px};
    }
}