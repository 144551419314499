.page {
    margin: auto;
    max-width: 1200px;
    padding: 0 .5em 10em;
}

.grid {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-gap: 2em 0px;

    > * {
        grid-column: 1 / 13;
    }

    @include breakpoint(medium) {
        padding: 1em;
        grid-gap: 4em 4em;

        > .section {
            grid-column: span 6; 
        }

        > .grid__col--span2 {
            grid-column: span 12;
        }
    }
    
}