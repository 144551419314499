.box-list {
    // margin: -1em 0 0 -1em;
}

.box-list > * {
    // margin: 1em 0 0 1em;
    position: relative;
    display: inline-block;
    padding: .5em 1em;
}

.box-list svg {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}