html,
body {
    font-size: 16px;
    color: $COLOR_BLACK;
    line-height: 1.4;
    font-family: "Open Sans", Helvetica, sans-serif;
}


.hdg {
    display: block;
    margin: 0 0 1rem;
    font-family: 'Fredericka the Great', sans-serif;
    font-weight: 400;
    line-height: 1.15;
}

.hdg--1 {
    margin-top: 0;
    font-size: 4.209em;
}

.hdg--2 {font-size: 2.369em;}

.hdg--3 {font-size: 1.333em;}

.hdg--nomargin {
    margin: 0;
}

.hdg--inline {
    display: inline-block;
}

p {margin-bottom: 1.25em;}

small, .text_small {font-size: 0.75em;}