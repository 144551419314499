.blocks {
    margin: -2em 0 0 -2em;
    display: flex;
    flex-wrap: wrap;
}

.block {
    display: flex;
    padding: 2em 0 0 2em;
    width: 100%;

    @include breakpoint(medium) {
        width: 50%;
    }

    @include breakpoint(large) {
        width: 33.3%;
    }
}

@include breakpoint(medium) {
    .block--showcase {
        width: 100%;
        padding: 3em 2em 2em 4em;
    }
}