* {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

img {
    max-width: 100%;
}

a {
    color: darken($COLOR_NOTEBOOK_BLUE, 20%);
}