.card__inner {
    position: relative;
    display: block;

    img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.card__title {
    display: block;
    padding: 1em;
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    background: rgba(darken(#174480, 20%),.5);
    font-weight: bold;
    color: white;
}